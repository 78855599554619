import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import NavigationBar from "../navigation-bar";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  getAllBookingsPassengersAndPassengerExtras,
  changeBookingStatus,
} from "../../../services/bookings";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { AxiosResponse } from "axios";
import { ToastContainer, toast } from "react-toastify";
import { deleteBooking, getAllBookings } from "../../../services/bookings";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import "./bookingSummary.css";

const BookingsPassengrsSummary: React.FC = () => {
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    getAllBookingsPassengersAndPassengerExtras().then((response) => {
      const tableData = response.data.data;
      const sortedData = [...tableData].sort((a, b) => {
        const numA = parseInt(a.bookingRefId.slice(2), 10);
        const numB = parseInt(b.bookingRefId.slice(2), 10);
        return numB - numA;
      });
    
      if (!_.isEmpty(sortedData)) {
        setTableData(sortedData);
      }
    });
  }, []);

  
  const handleTripStatusChange = (bookingId: number) => {
    changeBookingStatus(bookingId)
      .then((response: AxiosResponse) => {
        const restrcutredResponse: any = response.data;
        toast.success(restrcutredResponse.message, {
          position: "bottom-right",
        });
      })
      .catch((error: any) => {
        const response: any = error.response.data;
        toast.error(response.message, { position: "bottom-right" });
      });
  };
  const handleDeleteBooking = (id: any) => {
    deleteBooking(id)
      .then((response: AxiosResponse) => {
        const restrcutredResponse: any = response.data;
        toast.success(restrcutredResponse.message, {
          position: "bottom-right",
        });
      })
      .catch((error: any) => {
        const response: any = error.response.data;
        toast.error(response.message, { position: "bottom-right" });
      });
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <ToastContainer />
      <NavigationBar />
      <Box sx={{ maxWidth: "100%", margin: "2% 8%" }}>
        <Typography variant="h3" style={{ flexGrow: 1, textAlign: "center", marginBottom: "2%"}}>
          Booking / Passengers Summary
        </Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          {_.isEmpty(tableData) ? (
            <CircularProgress color="success" />
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow >
                  <TableCell className="table-header">Name</TableCell>
                  <TableCell className="table-header">Last Name</TableCell>
                  <TableCell className="table-header">Phone</TableCell>
                  <TableCell className="table-header">Email</TableCell>
                  <TableCell className="table-header">Passenger Count</TableCell>
                  <TableCell className="table-header">Travel Number</TableCell>
                  <TableCell className="table-header">Travel From</TableCell>
                  <TableCell className="table-header">Pickup Date</TableCell>
                  <TableCell className="table-header">Pickup Time</TableCell>
                  <TableCell className="table-header">Pickup Location</TableCell>
                  <TableCell className="table-header">DropOff Location</TableCell>
                  <TableCell className="table-header">Luggage Pieces</TableCell>
                  <TableCell className="table-header">Booking Ref Id</TableCell>
                  <TableCell className="table-header">Pickup Lank Mark</TableCell>
                  <TableCell className="table-header">Extras Description</TableCell>
                  <TableCell className="table-header">Child Seats</TableCell>
                  <TableCell className="table-header">Booster Seats</TableCell>
                  <TableCell className="table-header">Trip Status</TableCell>
                  <TableCell className="table-header">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.passengerCount}</TableCell>
                    <TableCell>{row.travelNumber}</TableCell>
                    <TableCell>{row.travelFrom}</TableCell>
                    <TableCell>{row.pickUpDate}</TableCell>
                    <TableCell>{row.PickUpTime}</TableCell>
                    <TableCell>{row.pickUpLocation}</TableCell>
                    <TableCell>{row.dropOffLocation}</TableCell>
                    <TableCell>{row.luggagePieces}</TableCell>
                    <TableCell>{row.bookingRefId}</TableCell>
                    <TableCell>{row.pickUpLandMark}</TableCell>
                    <TableCell>{row.extrasDescription}</TableCell>
                    <TableCell>{row.childSeats}</TableCell>
                    <TableCell>{row.boosterSeats}</TableCell>
                    <TableCell>{row.tripStatus}</TableCell>
                    <TableCell>
                      <div style={{display:"flex", alignItems: "center", cursor: "pointer"}}>
                      <div onClick={() => handleTripStatusChange(row.id)}>
                        <IconButton
                          color="primary"
                          aria-label="add to shopping cart"
                        >
                          <AutorenewIcon />
                        </IconButton>
                      </div>
                      <Delete onClick={() => handleDeleteBooking(row.id)} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
    </div>
  );
};

export default BookingsPassengrsSummary;
