import React, { useState } from "react";
import "./index.css";
import {
  Card,
  Typography,
  Box,
  Grid,
  InputLabel,
  Stack,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as Extras } from "../../assets/icons/extras.svg";
import { ReactComponent as RequiredSign } from "../../assets/icons/coolicon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { ReactComponent as BabySeats } from "../../assets/icons/toddler-1.svg";
import Booster from "../../assets/seats-orange.png";
import { ReactComponent as FreeTag } from "../../assets/icons/free-tag.svg";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import {
  PassengerDetailExtrasType,
  createPassengerExtra,
} from "../../services/passengers-detail-extras";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.min.css";
import { AxiosResponse } from "axios";
import Autocomplete from "react-google-autocomplete";
import { BookingType } from "../../services/bookings";

const ExtrasComponent: React.FC<{
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  passengerId: number;
  setPassengerExtrasDetails: React.Dispatch<
    React.SetStateAction<
      Omit<PassengerDetailExtrasType, "id" | "isDelete"> | undefined
    >
  >;
  bookingDetails: BookingType;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingType>>;
}> = (props) => {
  const [isPassengerInserting, setIsPassengerInserting] =
    useState<boolean>(false);
  const [boosterSeats, setBoosterSeats] = useState<number>(0);
  const [babySeats, setBabySeaters] = useState<number>(0);

  const handleAddPassengerExtra = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const passengerExtra: Omit<PassengerDetailExtrasType, "id" | "isDelete"> = {
      extrasDescription: String(formData.get("txtExtraDescription")) || "",
      boosterSeats: Number(formData.get("txtBoosterSeats")),
      childSeats: Number(formData.get("txtBabySeats")),
      passengerId: props.passengerId,
    }; 
    if (!_.isEmpty(passengerExtra) || !_.isUndefined(passengerExtra)) {
      setIsPassengerInserting(true);
      createPassengerExtra(passengerExtra)
        .then((response: AxiosResponse) => {
          props.setPassengerExtrasDetails(response.data.data);
          props.setActiveStep(props.activeStep + 1);
          setIsPassengerInserting(false);
        })
        .catch((error: any) => {
          const response: any = error.response.data;
          console.error(response.message);
        });
    }

  };
  const handlePrevious = () => {
    props.setActiveStep(props.activeStep - 1);
  };
  return (
    <>
      <Card className="extras-card-style">
        <Typography gutterBottom variant="h5" className="heading-style">
          <span className="back-icon-styles" onClick={handlePrevious}>
            <ArrowLeft />
          </span>
          <span>
            Extras <Extras className="icon-styles" />
          </span>
        </Typography>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleAddPassengerExtra}
        >
          <Grid container className="form-styles">
            <Grid
              xs={12}
              md={12}
              sm={12}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <InputLabel>
                Pickup Land Mark{" "}
                <span>
                  <RequiredSign
                    style={{ marginBottom: "-0.2%", marginLeft: "1%" }}
                  />
                </span>
                <br />
                <Autocomplete
                  className="landmark"
                  style={{
                    width: "95%",
                    border: "1px solid #341ea0",
                    borderRadius: "10px",
                    padding: "10px",
                    margin: "2% 0%",
                  }}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={(place) => {
                    props.setBookingDetails({
                      ...props.bookingDetails,
                      pickUpLandMark: place.name,
                    });
                  }}
                  options={{
                    fields: [
                      "address_components",
                      "geometry",
                      "icon",
                      "name",
                      "address",
                    ],
                    types: ["establishment"],
                    componentRestrictions: { country: "fr" },
                  }}
                  libraries={["places"]}
                />
              </InputLabel>
            </Grid>
            <Grid xs={12} md={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <div className="input-bx-style">
                <InputLabel>
                  Note for chauffeur{" "}
                </InputLabel>
                <textarea
                  style={{ padding: "10px 5px" }}
                  id="txtExtraDescription"
                  name="txtExtraDescription"
                  className="input-bx"
                />
              </div>
            </Grid>
            <Grid xs={12} md={6} className="row-style" mt={{ sm: 3, xs: 0 }}>
              <Box
                className="free-seats-styles"
                mb={1}
                ml={{ sm: 1.5, xs: 0, md: 1.5 }}
              >
                <Stack
                  direction="row"
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                  mt={1}
                >
                  <BabySeats className="seating-icon-style" />
                  <FreeTag />
                  <span className="seats-text">Baby Seats</span>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => {
                      if (babySeats > 0) {
                        setBabySeaters(babySeats - 1);
                      }
                    }}
                  >
                    <Minus />
                  </IconButton>
                  <input name="txtBabySeats" value={babySeats} type="hidden" />
                  <span className="seats-number">{babySeats}</span>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => setBabySeaters(babySeats + 1)}
                  >
                    <Add />
                  </IconButton>
                </Stack>
              </Box>
              <Box
                className="free-seats-styles"
                ml={{ sm: 1.5, xs: 0, md: 1.5 }}
                mb={{ sm: 0, xs: 1.5 }}
              >
                <Stack
                  direction="row"
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                  mt={1}
                >
                  <img alt="booster-seats" src={Booster} width={50} />
                  <FreeTag />
                  <span className="seats-text">Booster Seats</span>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => setBoosterSeats(boosterSeats - 1)}
                  >
                    <Minus />
                  </IconButton>
                  <input
                    name="txtBoosterSeats"
                    value={boosterSeats}
                    type="hidden"
                  />
                  <span className="seats-number">{boosterSeats}</span>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => setBoosterSeats(boosterSeats + 1)}
                  >
                    <Add />
                  </IconButton>
                </Stack>
              </Box>
            </Grid>
            <Button
              className="submit-styles-button"
              type="submit"
              variant="contained"
              disabled={isPassengerInserting ? true : false}
            >
              {isPassengerInserting ? (
                <CircularProgress color="inherit" />
              ) : (
                <>
                  {" "}
                  Continue booking {"  "}
                  <ArrowIcon className="submit-icon-style" />
                </>
              )}
            </Button>
          </Grid>
        </Box>
      </Card>
    </>
  );
};
export default ExtrasComponent;
