import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as HealthIcon } from "../../assets/icons/healthicons_travel-alt-outline.svg";
import { ReactComponent as PassengersDetails } from "../../assets/icons/passenger.svg";
import { ReactComponent as Extras } from "../../assets/icons/extras.svg";
import { ReactComponent as BabySeats } from "../../assets/icons/toddler-1.svg";
import Booster from "../../assets/seats-orange.png";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as FreeTag } from "../../assets/icons/free-tag.svg";
import { PassengerDetailsType } from "../../services/passengers-details";
import { PassengerDetailExtrasType } from "../../services/passengers-detail-extras";
import ConfirmationModal from "../confirmation-modal";
import {
  BookingType,
  createBooking,
  getBookingRef,
} from "../../services/bookings";
import "./index.css";
import { AxiosResponse } from "axios";

const BookingSummaryComponent: React.FC<{
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  passengerDetails: Omit<PassengerDetailsType, "id" | "isDelete"> | undefined;
  passengerExtrasDetails:
    | Omit<PassengerDetailExtrasType, "id" | "isDelete">
    | undefined;
  bookingDetails: BookingType;
  passengerId: number;
}> = (props) => {
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);
  const [bookingRefId, setBookingRefId] = useState<string>("");
  const [isBookingInserting, setIsBookingInserting] = useState<boolean>(false);

  useEffect(() => {
    getBookingRef()
      .then((response) => setBookingRefId(response.data.data))
      .catch((error) => {
        const response: any = error.response.data;
        console.error(response.message);
      });
  }, []);

  const handleConfirmBooking = () => {
    const booking = {
      ...props.bookingDetails,
      passengerId: props.passengerId,
    };
    setIsBookingInserting(true);
    createBooking(booking)
      .then((response: AxiosResponse) => {
        setIsBookingInserting(false);
        setConfirmationModalOpen(true);
      })
      .catch((error: any) => {
        const response: any = error.response.data;
        console.error(response.message);
      });
  };
 
  const handlePrevious = () => {
    props.setActiveStep(props.activeStep - 1);
  };
  return (
    <>
      <Card className="booking-detail-card-style">
        <Typography gutterBottom variant="h5" className="heading-style-booking">
          <span className="back-icon-styles" onClick={handlePrevious}>
            <ArrowLeft />
            <HealthIcon className="health-icon-styles" /> BOOKING SUMMARY
          </span>
          <span className="booking-ref">Booking Referance: {bookingRefId}</span>
        </Typography>

        <Grid
          container
          spacing={{ md: 3 }}
          rowGap={{ xs: 2, md: 0 }}
          className="grid-space"
        >
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="h5"
              mt={3}
              className="sub-heading-style"
            >
              Passenger Details
              <span>
                <PassengersDetails className="icon-styles" />
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="data-heading">
              Name
            </Typography>
            <Typography className="data-heading-item">
              {props.passengerDetails?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="data-heading">
              Email
            </Typography>
            <Typography className="data-heading-item">
              {props.passengerDetails?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="data-heading">
              Contact Number
            </Typography>
            <Typography className="data-heading-item">
              {props.passengerDetails?.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="data-heading">
              Flight/Train Number
            </Typography>
            <Typography className="data-heading-item">
              {props.passengerDetails?.travelNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="data-heading">
              Flight/Train from
            </Typography>
            <Typography className="data-heading-item">
              {props.passengerDetails?.travelFrom}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>

        <Grid
          container
          spacing={{ md: 3 }}
          rowGap={{ xs: 2, md: 0 }}
          className="grid-space"
        >
          <Grid item xs={12} mt={3}>
            <Typography gutterBottom variant="h5" className="sub-heading-style">
              Extras
              <span>
                <Extras className="icon-styles" />
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="data-heading">
              Notes for chauffeur
            </Typography>
            <Typography className="data-heading-item">
              {props.passengerExtrasDetails?.extrasDescription}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} container direction="column" spacing={2}>
            <Box className="free-seats-styles-summary">
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={1}
              >
                <BabySeats className="seating-icon-style" />
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="seats-text-styles">Baby Seats</span>
                  <FreeTag />
                </Stack>

                <span className="seats-number">
                  {props.passengerExtrasDetails?.childSeats
                    ? props.passengerExtrasDetails?.childSeats
                    : 0}
                </span>
              </Stack>
            </Box>
            <Box className="free-seats-styles-summary">
              {" "}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={1}
              >
                <img alt="booster-seats" src={Booster} width={50} />
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="seats-text-styles">Booster Seats</span>
                  <FreeTag />
                </Stack>

                <span className="seats-number">
                  {props.passengerExtrasDetails?.boosterSeats
                    ? props.passengerExtrasDetails?.boosterSeats
                    : 0}
                </span>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              className="summary-button-submit"
              type="submit"
              variant="contained"
              disabled={isBookingInserting ? true : false}
              onClick={handleConfirmBooking}
            >
              {isBookingInserting ? (
                <CircularProgress color="inherit" />
              ) : (
                <>Confirm Booking {"  "}</>
              )}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <ConfirmationModal
        isModalVisible={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      />
    </>
  );
};
export default BookingSummaryComponent;
