import React from "react";
import { AppBar, Grid, Stack } from "@mui/material";
import SSL from "../../assets/icons/image 8.png";
import SSL2 from "../../assets/icons/ssl_secure_badge 1.png";
import { ReactComponent as FB } from "../../assets/icons/fb.svg";
import { ReactComponent as Insta } from "../../assets/icons/insta.svg";
import { ReactComponent as Pin } from "../../assets/icons/trip-ad.svg";
import { ReactComponent as Twit } from "../../assets/icons/_x30_4.Twitter.svg";
import "./index.css";

const FooterComponent: React.FC = () => {
  return (
    <footer className="footer">
      <AppBar position="static" className="app-bar-footer">
        <Grid  className="footer-grid" >
          <Grid >
            {" "}
            <Stack direction="row" alignItems="center" spacing={2}>
              <img alt="booster-seats" src={SSL} width={50} />
              <img alt="booster-seats" src={SSL2} width={50} />
              <h6>Officially approved by Government of France</h6>
            </Stack>
          </Grid>
          <Grid className="grid-follow-us">
            <h5 className="follow-us-title">Follow us on</h5>
            <br />
            <div className="grid-style-icon">
              <span
                className="follow-us"
                onClick={() => {
                  window.location.href = "https://parisflextransfer.com/";
                }}
              >
                <FB />
              </span>
              <span
                className="follow-us"
                onClick={() => {
                  window.location.href = "https://parisflextransfer.com/";
                }}
              >
                <Insta />
              </span>
              <span
                className="follow-us"
                onClick={() => {
                  window.location.href =
                    "https://www.tripadvisor.com/Attraction_Review-g187147-d25411505-Reviews-Paris_Flex_Transfer-Paris_Ile_de_France.html";
                }}
              >
                <Pin className="follow-us-trip" />
              </span>
              <span
                className="follow-us"
                onClick={() => {
                  window.location.href = "https://parisflextransfer.com/";
                }}
              >
                <Twit />
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <h6>Copyright © 2023 Paris Flex Transfer </h6>
          </Stack>
        </Grid>
      </AppBar>
    </footer>
  );
};
export default FooterComponent;
