import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as PassengersDetails } from "../../assets/icons/carbon_passenger-plus.svg";
import { ReactComponent as RequiredSign } from "../../assets/icons/coolicon.svg";
import { ReactComponent as TriangleIcon } from "../../assets/icons/triangle-icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import "./index.css";
import {
  PassengerDetailsType,
  createPassenger,
} from "../../services/passengers-details";
import "react-toastify/dist/ReactToastify.min.css";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  contact_no: yup.string().required("Contact number is required"),
  flight_train_no: yup.string().required("Flight/train number is required"),
  flight_train_from: yup.string().required("Flight/train from is required"),
});

const PassengerDetails: React.FC<{
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  setPassengerId: React.Dispatch<React.SetStateAction<number>>;
  setPassengerDetails: React.Dispatch<
    React.SetStateAction<
      Omit<PassengerDetailsType, "id" | "isDelete"> | undefined
    >
  >;
  pasengers: any;
}> = (props) => {
  const [isPassengerInserting, setIsPassengerInserting] =
    useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      contact_no: "",
      flight_train_no: "",
      flight_train_from: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const passengerCount =
        props.pasengers === "NONE" ? 0 : Number(props.pasengers);
      const passenger: Omit<PassengerDetailsType, "id" | "isDelete"> = {
        name: values.first_name,
        lastName: values.last_name,
        email: values.email,
        passengerCount: passengerCount,
        phone: values.contact_no,
        travelFrom: values.flight_train_no,
        travelNumber: values.flight_train_from,
      };
      if (!_.isEmpty(passenger) || !_.isUndefined(passenger)) {
        setIsPassengerInserting(true);
        createPassenger(passenger)
          .then((response: AxiosResponse) => {
            const restrcutredResponse: any = response.data;
            props.setActiveStep(props.activeStep + 1);
            props.setPassengerId(restrcutredResponse.data.id);
            props.setPassengerDetails(restrcutredResponse.data);
            setIsPassengerInserting(false);
          })
          .catch((error: any) => {
            const response: any = error.response.data;
            console.error(response.message);
          });
      }
    },
  });

  return (
    <>
      <Card className="passenger-detail-card-style-form">
        <Typography gutterBottom variant="h5" className="heading-style">
          Passenger Details
          <span>
            <PassengersDetails className="icon-styles" />
          </span>
        </Typography>
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container className="form-styles">
            <Grid item sm={6} xs={12} style={{ verticalAlign: "center" }}>
              <InputLabel className="input-label-style">
                Name
                <span>
                  <RequiredSign
                    style={{ marginBottom: "-1%", marginLeft: "1%" }}
                  />
                </span>
              </InputLabel>
              <TextField
                className="textfield-mui"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    width: "100%",
                    marginTop: "1.5%",
                  },
                }}
                id="first_name"
                name="first_name"
                size="medium"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
              />
              {formik.touched.first_name && formik.touched.first_name && (
                <FormHelperText error className="error-text">
                  {formik.errors.first_name}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel>
                Last Name{" "}
                <span>
                  <RequiredSign style={{ marginBottom: "-1%" }} />
                </span>
              </InputLabel>
              <TextField
                className="textfield-mui"
                variant="standard"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: {
                    width: "100%",
                    marginTop: "1.5%",
                  },
                }}
                id="last_name"
                name="last_name"
                size="medium"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
              />
              {formik.touched.last_name && formik.touched.last_name && (
                <FormHelperText error className="error-text">
                  {formik.errors.last_name}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel>
                Email{" "}
                <span>
                  <RequiredSign style={{ marginBottom: "-1%" }} />
                </span>
              </InputLabel>
              <TextField
                className="textfield-mui"
                variant="standard"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: {
                    width: "100%",
                    marginTop: "1.5%",
                  },
                }}
                id="email"
                name="email"
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {formik.touched.email && formik.touched.email && (
                <FormHelperText error className="error-text">
                  {formik.errors.email}
                </FormHelperText>
              )}
              <p className="warning-text" style={{ marginBottom: "6%" }}>
                <TriangleIcon style={{ marginRight: "1%" }} /> We will send you
                booking details here
              </p>
            </Grid>

            <Grid item sm={6} xs={12}>
              <InputLabel>
                Contact Number{" "}
                <span>
                  <RequiredSign style={{ marginBottom: "-1%" }} />
                </span>
              </InputLabel>

              <MuiTelInput
                className="tel-input"
                id="contact_no"
                name="contact_no"
                size="medium"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    width: "100%",
                    marginTop: "1.5%",
                  },
                }}
                value={formik.values.contact_no}
                defaultCountry="FR"
                onChange={(value: any) => {
                  matchIsValidTel(value);
                  formik.setFieldValue("contact_no", value);
                }}
                error={
                  formik.touched.contact_no && Boolean(formik.errors.contact_no)
                }
              />
              {formik.touched.contact_no && formik.touched.contact_no && (
                <FormHelperText error className="error-text">
                  {formik.errors.contact_no}
                </FormHelperText>
              )}
              <div className="warning-text" style={{ marginBottom: "2%" }}>
                <TriangleIcon style={{ marginRight: "1%" }} />
                Please provide us a working phone number in France. <br />
                We will contact you using WhatsApp
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel>
                Flight/ train number{" "}
                <span>
                  <RequiredSign style={{ marginBottom: "-1%" }} />
                </span>
              </InputLabel>
              <TextField
                className="textfield-mui"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    width: "100%",
                    marginTop: "1.5%",
                  },
                }}
                id="flight_train_no"
                name="flight_train_no"
                size="medium"
                value={formik.values.flight_train_no}
                onChange={formik.handleChange}
                error={
                  formik.touched.flight_train_no &&
                  Boolean(formik.errors.flight_train_no)
                }
              />
              {formik.touched.flight_train_no &&
                formik.touched.flight_train_no && (
                  <FormHelperText error className="error-text">
                    {formik.errors.flight_train_no}
                  </FormHelperText>
                )}
              <p className="warning-text" style={{ marginBottom: "6%" }}>
                <TriangleIcon style={{ marginRight: "1%" }} /> If you does not
                use any flight or train, enter 000 to continue your booking.
              </p>
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel>
                Flight/ train from{"  "}
                <span>
                  <RequiredSign style={{ marginBottom: "-1%" }} />
                </span>
              </InputLabel>
              <TextField
                className="textfield-mui"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    width: "100%",
                    marginTop: "1.5%",
                  },
                }}
                id="flight_train_from"
                name="flight_train_from"
                size="medium"
                value={formik.values.flight_train_from}
                onChange={formik.handleChange}
                error={
                  formik.touched.flight_train_from &&
                  Boolean(formik.errors.flight_train_from)
                }
              />
              {formik.touched.flight_train_from &&
                formik.touched.flight_train_from && (
                  <FormHelperText error className="error-text">
                    {formik.errors.flight_train_from}
                  </FormHelperText>
                )}
            </Grid>
            <Grid item sm={12} xs={12}>
              <Button
                className="submit-styles-button"
                type="submit"
                variant="contained"
                disabled={isPassengerInserting ? true : false}
              >
                {isPassengerInserting ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <>
                    {" "}
                    Continue booking {"  "}
                    <ArrowIcon className="submit-icon-style" />
                  </>
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};
export default PassengerDetails;
