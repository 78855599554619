import React from "react";
import { AppBar, Stack, Grid } from "@mui/material";
import { ReactComponent as ParisFlex } from "../../assets/Logo.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import "./index.css";

const HeaderComponent: React.FC = () => {
  const handleClick = () => {
    window.location.href = "https://parisflextransfer.com/";
  };
  return (
    <>
      <AppBar position="static" className="app-bar">
        <Grid container spacing={2} className="grid-style-initial">
          <Grid item lg={6} md={6} sm={12} xs={12} className="grid-style">
            <span onClick={handleClick}>
              <ParisFlex className="paris-svg" />
            </span>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className="grid-style">
            <Stack spacing={3} direction="row" justifyContent="flex-end" alignItems="center">
              <span className="support-styles">Need a support?</span>
              <Phone className="phone-num-svg" />{" "}
              <span className="phone-num">
                <a
                  href="tel:+33 7 80 86 86 04"
                  className="phone-num"
                >
                  {" "}
                  +33 7 80 86 86 04{" "}
                </a>
              </span>
            </Stack>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};
export default HeaderComponent;
